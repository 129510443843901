<template>
  <div
    v-if="quote"
    class="bg-white"
  >
    <h3 class="bg-yellow text-2xl text-center text-white py-[10px]">
      A nap idézete
    </h3>
    <div v-if="getMainImgQuote(quote)">
      <RePictureSet
        :image="getMainImgObjQuote(quote)"
        image-class="img-fluid w-100 lazyload"
        sizes="180px"
      />
      <p
        v-if="quote.image"
        class="text-center"
      >
        <strong v-if="quote.image.title">{{
          quote.image.title
        }}</strong>
        <br v-if="quote.image.title && quote.image.subtitle">
        <span v-if="quote.image.subtitle">{{
          quote.image.subtitle
        }}</span>
      </p>
    </div>
    <p class="text-red font-semibold p-[20px]">
      <nuxt-link
        :to="
          '/folkszemle/idezetgyujtemeny/idezet/' +
            quote.id +
            '/' +
            quote.slug
        "
        title="Idézetgyűjtemény"
      >
        {{ quote.title }}
      </nuxt-link>
    </p>
    <p class="px-[20px]">
      &bdquo;{{ quote.content }}&rdquo;
    </p>
    <p
      v-if="quote.sources && quote.sources.length"
      class="p-[20px]"
    >
      <i
        v-for="source in quote.sources"
        :key="$unique() + source.name"
        class="author"
      >{{ source.source }}</i>
      {{ quote.source }}
    </p>
    <div class="text-center px-[20px]">
      <nuxt-link
        to="/folkszemle/idezetgyujtemeny"
        title="Idézetgyűjtemény"
        class="link link-yellow"
      >
        Idézetgyűjtemény
      </nuxt-link>
    </div>
    <img
       
      src="/img/idezet_rajz.svg"
      class="mx-auto mt-[50px] pb-[15px] h-[40px]"
      alt=""
    > 
  </div>
</template>
<script>
import RePictureSet from '~/components/rxt/recomponent/RePictureSet.vue'

export default {
  components: {
    RePictureSet
  },
  data(){
    return {
      quote: null
    }
  },
  fetch(){
    this.$axios.get("quotes?sort=random&filter[daily]=true").then(response => {
      this.quote = response.data.data[0];
    })
  },
  methods: {
    getMainImgObjQuote(entity) {
      if (entity.image && entity.image.url) {
        return entity.image;
      }
      return null;
    },
    getMainImgQuote(entity) {
      if (entity.image && entity.image.url) {
        return entity.image.url;
      }
      return null;
    }
  }
}
</script>